import React, { Component } from "react";
import ReactDOM from "react-dom";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
// They don't seem to have this theme anymore in the dist folder
// import '@okta/okta-signin-widget/dist/css/okta-theme.css';

// Derived from: https://developer.okta.com/code/react/okta_react_sign-in_widget/#create-a-widget-wrapper

export default class OktaSignInWidget extends Component {
  componentDidMount() {
    // Disabling eslint rule because this code is borrowed from the Okta tutorial
    const el = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
    this.widget = new OktaSignIn({
      baseUrl: this.props.baseUrl,
      registration: {
        parseSchema: function(schema, onSuccess) {
          // handle parseSchema callback
          onSuccess(schema);
        },
        preSubmit: function(postData, onSuccess) {
          // handle preSubmit callback
          onSuccess(postData);
        },
        postSubmit: function(response, onSuccess) {
          // handle postsubmit callback
          onSuccess(response);
        }
      },
      features: {
        // Used to enable registration feature on the widget.
        // https://github.com/okta/okta-signin-widget#feature-flags
        registration: true // REQUIRED
      }
    });
    this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    return <div />;
  }
}
