import React from "react";
import { navigate } from "gatsby";
import { useOktaAuth } from "@okta/okta-react/dist/OktaContext";
import Security from "@okta/okta-react/dist/Security";
import authService from "../common/auth-service";
import OktaSignInWidget from "../components/auth/okta-signin-widget";
import log from "../common/log";

const Login = () => {
  const { authService, authState } = useOktaAuth();
  const { isAuthenticated } = authState;

  React.useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const onSuccess = res => {
    if (res.status === "SUCCESS") {
      return authService.redirect({
        sessionToken: res.session.token
      });
    } else {
      // The user can be in another authentication state that requires further action.
      // For more information about these states, see:
      //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
    }
  };

  const onError = err => {
    log.error("error logging in", err);
  };

  if (isAuthenticated === false) {
    return (
      <OktaSignInWidget
        onSuccess={onSuccess}
        onError={onError}
        baseUrl={process.env.AUTH_BASE_URL}
      />
    );
  }

  return null;
};

export default function ConnectedLogin(props) {
  if (typeof window === "undefined") return null;
  return (
    <Security authService={authService}>
      <Login {...props} />
    </Security>
  );
}
