import log from "loglevel";
import { isWindowDefined } from "./utils";

log.setDefaultLevel("warn");

if (isWindowDefined()) {
  window.setLogLevel = log.setLevel;
}

export default log;
